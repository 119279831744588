import React, { useEffect, useState, useRef } from "react";
import style from "./OpportunityUpdate.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuImagePlus } from "react-icons/lu";
import { baseUrl } from "../Url";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate ,useLocation} from "react-router-dom";

const CustomQuill = styled(ReactQuill)`
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border: none;
  }
`;

const OpportunityUpdate = () => {
  const fileInputRef = useRef(null);

  const location =useLocation()
  const navigate =useNavigate()
  const state =location.state
  const opportunity_id=state?.opportunity_id

  const [isFocused, setIsFocused] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen2, setSnackbarOpen2] = useState(false);
  const [snackbarMessage2, setSnackbarMessage2] = useState("");
  const [snackbarSeverity2, setSnackbarSeverity2] = useState("success");
  const [opportunityData, setOpportunityData] = useState({
    host_name: "",
    host_profile_image: null,
    opportunity_details: "",
    opportunity_discription: "",
    opportunity_id:"",
    opportunity_title:"",
    opportunity_type:"",
    user_id:"",
    user_name:"",
    location:"",
    end_date:""

  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setOpportunityData({
            ...opportunityData,
            host_profile_image:reader.result
        })
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setOpportunityData({
        ...opportunityData,
        host_profile_image:null
    })
  };

  async function opportunityGet() {
    await fetch(`${baseUrl}/duno/opportunity/single`, {
      method: "Post",
      body: JSON.stringify({
        opportunity_id: opportunity_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "opp");
        if (data.status === true) {
          const endDate = new Date(data.data.end_date.$date).toISOString().slice(0, 16);
          setOpportunityData({
              ...data.data,
              end_date: endDate
          });
        }
      });
  }

  const updateOpportunity = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!opportunityData.opportunity_title.trim()) {
      setSnackbarMessage("Opportunity title must not be empty.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      isValid = false;
    }

    if (!opportunityData.opportunity_details.trim()) {
      setSnackbarMessage2("Opportunity details must not be empty.");
      setSnackbarSeverity2("error");
      setSnackbarOpen2(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("user_id", opportunityData.user_id);
      formData.append("opportunity_id", opportunityData.opportunity_id);
      formData.append("opportunity_title", opportunityData.opportunity_title);
      formData.append("host_name", opportunityData.host_name);
      formData.append("opportunity_discription", opportunityData.opportunity_discription);
      formData.append("opportunity_details", opportunityData.opportunity_details);
      formData.append("location", opportunityData.location);
      formData.append("end_date", opportunityData.end_date);
      if (opportunityData.host_profile_image!==null) {
        formData.append("host_profile_image", opportunityData.host_profile_image);
      }

      const response = await fetch(`${baseUrl}/duno/opportunity/edit`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (result.status) {
        setSnackbarMessage("Opportunity Updated successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/opportunity");
        }, 2000);
      } else {
        setSnackbarMessage("Failed to add Opportunity: " + result.description);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding event:", error);
      setSnackbarMessage("An error occurred while adding the Opportunity.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(()=>{
    if(opportunity_id!==undefined){
      opportunityGet()
    }

  },[opportunity_id])

  return (
    <div className={style.container}>
    <div className={style.header}>
      <div className={style.headerText}>
        <h3>Update your Opportunity</h3>
      </div>
    </div>
    <div className={style.form}>
      <div className={style.createBox}>
        <form onSubmit={updateOpportunity}>
          <div className={style.hostName}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Host Name"
                variant="outlined"
                value={opportunityData.host_name}
                onChange={(e) => {
                    setOpportunityData({
                        ...opportunityData,
                        host_name:e.target.value
                    })
                }}
                required
              />
            </Box>
          </div>

          <div className={style.eventTitle}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Opportunity Title"
                variant="outlined"
                value={opportunityData.opportunity_title}
                onChange={(e) => {
                    setOpportunityData({
                        ...opportunityData,
                        opportunity_title:e.target.value
                    })
                }}
                required
              />
            </Box>
          </div>

          <div className={style.discription}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                value={opportunityData.opportunity_discription}
                onChange={(e) => {
                    setOpportunityData({
                        ...opportunityData,
                        opportunity_discription:e.target.value
                    })
                }}
                required
              />
            </Box>
          </div>

          <div className={style.eventDetialsBox}>
            <p>Details</p>
            <div
              className={`${style.eventDetails} ${
                isFocused ? style.focused : ""
              }`}
            >
              <div className={style.quillBox}>
                <CustomQuill
                  className={style.quill}
                  theme="snow"
                  value={opportunityData.opportunity_details}
                  onChange={(e) => {
                    setOpportunityData({
                        ...opportunityData,
                        opportunity_details:e.target.value
                    })
                }}
                  onFocus={() => setIsFocused(true)}
                />
              </div>
            </div>
          </div>

          <div className={style.modePosterTime}>
            <div className={style.dateTime}>
              <div className={style.modeContainer}>
                <div className={style.modeMenu}>
                  <TextField
                    id="outlined-basic"
                    label="Location"
                    variant="outlined"
                    value={opportunityData.location}
                    onChange={(e) => {
                        setOpportunityData({
                            ...opportunityData,
                            location:e.target.value
                        })
                    }}
                    fullWidth
                    autoComplete="off"
                  />
                </div>
                
                <div className={style.endDate}>
                  <label>End Date & Time</label>
                  <input
                    type="datetime-local"
                    name="endDate"
                    id="endDate"
                    required
                    placeholder="End Date"
                    value={opportunityData.end_date}
                    onChange={(e) => {
                        setOpportunityData({
                            ...opportunityData,
                            end_date:e.target.value
                        })
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={style.eventPoster}>
              <div className={style.posterContainer}>
                <div className={style.uploadBtn}>
                  <input
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                  />
                  <button type="button" onClick={handleButtonClick}>
                    Add Image <LuImagePlus className={style.imgIcon} />
                  </button>
                </div>
                {opportunityData.host_profile_image && (
                  <div className={style.posterImage}>
                    <img src={opportunityData.host_profile_image} alt="Event Poster" />
                    <FaRegTrashAlt
                      className={style.remove}
                      title="remove"
                      onClick={handleImageRemove}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={style.submitButton}>
            <button type="submit">Update </button>
          </div>
        </form>
      </div>
    </div>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
    <Snackbar
      open={snackbarOpen2}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen2(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => setSnackbarOpen2(false)}
        severity={snackbarSeverity2}
        sx={{ width: "100%" }}
      >
        {snackbarMessage2}
      </Alert>
    </Snackbar>
  </div>
  );
};

export default OpportunityUpdate;
