import React from "react";
import style from "./QuizListing.module.css";
import { baseUrl } from "../Url";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { RiEditCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { IoCreateOutline } from "react-icons/io5";
import { LuSettings } from "react-icons/lu";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { MdClose } from "react-icons/md";

const QuizListing = () => {
  const { profile } = useSelector((state) => state.profileData);
  const adminStatus = localStorage.getItem("adminStatus");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const [organizationDetail, setOrganizationDetail] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [buttonShow, setButtonShow] = useState(false);
  const [quizListData, setQuizListData] = useState([]);
  const [validation, setValidation] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [settingView, setSettingView] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [showingType, setShowingType] = useState("");
  const [quizStatus, setQuizStatus] = useState("draft");
  const[quizID,setQuizID]=useState("")
  const[showUpdateButton,setShowUpdateButton]=useState(false)
  const[updateSuccess,setUpdateSuccess]=useState(false)


  const validateForm = () => {

    if (startTime.trim() === "") {
      setErrorValue("Please select starting time")
      return false;
    }

    if (endTime.trim() === "") {
      setErrorValue("Please select ending time")
      return false;
    }

    if (showingType.trim() === "") {
      setErrorValue("Please select showing type")
      return false;
    }
   return true;
   
  };



  //Organization list API
  async function OrgGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrganizationDetail(data.data);
      });
  }

  //quiz list
  async function quizList(id) {
    await fetch(`${baseUrl}/quiz/list`, {
      method: "POST",
      body: JSON.stringify({
        org_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setQuizListData(data.data);
      });
  }


  async function quizSettingEdit() {
    if (!validateForm()) {
      setValidation(true);
      return;
    } else {
    await fetch(`${baseUrl}/quiz/setting/edit`, {
      method: "POST",
      body: JSON.stringify({
        quiz_id:quizID,
        settings:[
          {
          start_time:startTime,
          end_time:endTime,
          publish_status:quizStatus,
          random_quiz:showingType
        }
        ]
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status==true){
          setSettingView(false)
          setStartTime("")
          setEndTime("")
          setQuizStatus("draft")
          setShowingType("")
          setShowUpdateButton(false)
          quizList(selectedOrgId)
          setUpdateSuccess(true)
        }
        
      });
  }
}

  useEffect(() => {
    if ((adminStatus === false || adminStatus === "false")&&userRole!=="Partner") {
      setButtonShow(true);
      const orgId = profile?.org_id;
      if (orgId !== undefined && orgId !== "") {
        setSelectedOrgId(orgId);
        quizList(orgId);
      }
    } else {
      OrgGet();
      setButtonShow(false);
    }
  }, [adminStatus, profile]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={updateSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setUpdateSuccess(false);
        }}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {"Settings updated successfully"}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validation}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {errorValue}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Dive Into Knowledge: Quiz Library</p>
        </div>
        <div className={style.headerButton}>
          {buttonShow ? (
            <button
              onClick={() => {
                navigate("/quiz/create", { state: { orgId: selectedOrgId } });
              }}
            >
              Create
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {(adminStatus === true || adminStatus === "true")||((adminStatus === false || adminStatus === "false")&&userRole==="Partner")  ?(
        <div className={style.orgSelection}>
          <FormControl sx={{ width: "50%" }}>
            <InputLabel id="demo-simple-select-label">
              Select Organization
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select OrganiZation"
              value={selectedOrgId}
              onChange={(event) => {
                const OrgId = event.target.value;
                setSelectedOrgId(OrgId);
                quizList(OrgId);
                setButtonShow(true);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {organizationDetail && organizationDetail.length > 0 ? (
                organizationDetail.map((org) => (
                  <MenuItem key={org.org_id} value={org.org_id}>
                    {org.org_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No organizations available</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      ) : (
        ""
      )}
      <div className={style.quizListingSection}>
        <div className={style.quizGrid}>
          {quizListData &&
            quizListData.map((list, i) => {
              return (
                <List sx={{ width: "100%" }} key={i}>
                  <ListItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                      padding: "0.75rem",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemText
                      sx={{
                        fontSize: "1.5rem",
                        textTransform: "capitalize",
                        alignItems: "center",
                      }}
                    >
                      {list.quiz_name}
                    </ListItemText>
                    <div className={style.quizSettings}>
                      <Tooltip title="Quiz setting">
                        <IconButton
                          onClick={() => {
                            setSettingView(true);
                            setQuizID(list.quiz_id)
                            if (list.settings && list.settings.length > 0) {
                              setShowUpdateButton(true)
                              setStartTime(list.settings[0]?.start_time);
                              setEndTime(list.settings[0]?.end_time);
                              setShowingType(list.settings[0]?.random_quiz);
                              setQuizStatus(list.settings[0]?.publish_status);
                            }
                            else{
                              setShowUpdateButton(false)
                              setStartTime("")
                              setEndTime("")
                              setQuizStatus("draft")
                              setShowingType("")
                            }
                          }}
                        >
                          <LuSettings />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.quizCreateIcon}>
                      <Tooltip title="Add new quiz">
                        <IconButton>
                          <IoCreateOutline
                            onClick={() => {
                              navigate("/quiz/create", {
                                state: {
                                  orgId: selectedOrgId,
                                  quizId: list.quiz_id,
                                },
                              });
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.quizEditIcon}>
                      <Tooltip title="Edit quiz">
                        <IconButton
                          onClick={() => {
                            if (list.questions.length > 0) {
                              navigate("/quiz/edit", {
                                state: {
                                  orgId: selectedOrgId,
                                  quizId: list.quiz_id,
                                },
                              });
                            } else {
                              setValidation(true);
                              setErrorValue("Please create one question");
                            }
                          }}
                        >
                          <RiEditCircleLine />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ListItem>
                </List>
              );
            })}
        </div>
      </div>
      {settingView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.settingsListing}>
            <div className={style.settingHeader}>
              <div className={style.headerSettingText}>
                <h5>Add your settings</h5>
              </div>

              <div className={style.settingClose}>
                <MdClose
                  onClick={() => {
                    setSettingView(false)
                    setStartTime("")
                    setEndTime("")
                    setQuizStatus("draft")
                    setShowingType("")
                  }}
                />
              </div>
            </div>

            <div className={style.settingSection}>
              <div className={style.mainSection}>
                <div className={style.sectionValues}>
                <label htmlFor="">Start Time</label>
                  <input
                    type="time"
                    name="startTime"
                    id="startTime"
                    required
                    placeholder="Start Time"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />

                </div>
                <div className={style.sectionValues}>
                <label htmlFor="">End Time</label>
                  <input
                    type="time"
                    name="endTime"
                    id="endTime"
                    required
                    placeholder="End Time"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  />

                  
                </div>
              </div>
              <div className={style.mainSection}>
              <div className={style.sectionValues}>
              <label htmlFor="">Status</label>
                  <FormControl sx={{ width: "100%", margin: "0.5rem 0" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select status"
                      value={quizStatus}
                      onChange={(e) => {
                        setQuizStatus(e.target.value);
                      }}
                    >
                      <MenuItem value={"draft"}>Draft</MenuItem>
                      <MenuItem value={"publish"}>Publish</MenuItem>
                    </Select>
                  </FormControl>

                </div>
                <div className={style.sectionValues}>
                <label htmlFor="">Random quiz</label>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select showing type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select showing type"
                      value={showingType}
                      onChange={(e) => {
                        setShowingType(e.target.value);
                      }}
                    >
                      <MenuItem value={"randomquiz"}>Random quiz</MenuItem>
                      <MenuItem value={"normal"}>Normal</MenuItem>
                    </Select>
                  </FormControl>

                </div>
              </div>
            </div>
            <div className={style.settingButton}>
              <button className={style.add} onClick={() => {
                  quizSettingEdit()
                }}>{showUpdateButton?"Update":"Add"}</button>
              <button
                className={style.cancel}
                onClick={() => {
                  setSettingView(false)
                  setStartTime("")
                  setEndTime("")
                  setQuizStatus("draft")
                  setShowingType("")
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default QuizListing;
