import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../action/User";
import logo from "../../assets/logo.png";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMesssage] = useState("")

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { users } = useSelector((state) => state.userReducer);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlelogin = (e) => {
    const data = {
      username: username,
      password: password,
    };
    // e.preventDefault();
    dispatch(loginAction(data));
  };

  useEffect(() => {
    if (users?.status === "success" && users?.data.is_superadmin === true) {
      navigate("/organization");
    }else if (users?.status === "success" && users?.data.is_superadmin === false && users?.data.role=== "Partner") {
      navigate("/organization");
    } else if (users?.status === "success" && users?.data.is_superadmin === false) {
      navigate("/club");
    } else if (users?.message === "Login Failed. Please provide valid credentials.") {
      // navigate("/login")
      console.log(users);
      setErrorMesssage('Invalid Credentials')
    } else if (users?.status === "failed") {
      setErrorMesssage("You are not authorised to access this page")
    }
  }, [users]);
  return (
    <div className={style.Container}>
      <div className={style.Login}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={style.form}>
          {/* <div className={style.HeaderContent}>
            <h4>Sign In Admin</h4>
          </div> */}
          <div className={style.loginForm}>
            {errorMessage && <p>{errorMessage}</p>}
            <input
              className={style.input}
              type="text"
              value={username}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder="Username"
            />
            <div className={style.password}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorMesssage("")
                }}
              />
              {showPassword ? (
                <BsFillEyeSlashFill onClick={togglePasswordVisibility} />
              ) : (
                <BsFillEyeFill onClick={togglePasswordVisibility} />
              )}
            </div>

            <button
              onClick={() => {
                handlelogin();
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
