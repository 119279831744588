import React,{useEffect,useState} from 'react'
import style from './OpportunityDetails.module.css'
import { IoLocationOutline } from "react-icons/io5";
import { useLocation,useNavigate } from 'react-router-dom';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { baseUrl } from "../Url";

const OpportunityDetails = () => {

  const location =useLocation()
  const navigate =useNavigate()
  const state =location.state
  const opportunity_id=state?.opportunity_id


  const [opportunityData,setOpportunityData]=useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSuccess,setDeleteSuccess]=useState(false)

  async function opportunityGet() {
    await fetch(`${baseUrl}/duno/opportunity/single`, {
      method: "Post",
      body: JSON.stringify({
        opportunity_id: opportunity_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "opp");
        if (data.status === true) {
          setOpportunityData(data.data);
        }
      });
  }

  async function opportunityDelete() {
    await fetch(`${baseUrl}/duno/opportunity/delete`, {
      method: "Post",
      body: JSON.stringify({
        opportunity_id: opportunity_id,
        user_id:opportunityData.user_id
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "opp");
        if (data.status === true) {
          setShowDeleteModal(false)
          setDeleteSuccess(true)
          setTimeout(() => {
            navigate("/opportunity")
          }, 2000);
        }
      });
  }

  useEffect(()=>{
    if(opportunity_id!==undefined){
      opportunityGet()
    }

  },[opportunity_id])

  return (
    <div className={style.container}>
      <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={deleteSuccess}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setDeleteSuccess(false);
         
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {"Opportunity deleted successfully"}
        </Alert>
      </Snackbar>
        <div className={style.header}>
            <div className={style.headerText}><p>{opportunityData.opportunity_title}</p></div>
            <div className={style.headerButton}>
              <button className={style.updateBtn} onClick={()=>{
                navigate("/opportunity/update",{
                  state:{
                    opportunity_id:opportunity_id
                  }
                })
              }}>Update</button><button className={style.deleteBtn} onClick={()=>{
                setShowDeleteModal(true)
              }}>Delete</button>
            </div>
        </div>
        <div className={style.body}>
            <div className={style.bodyHeader}>
            <div className={style.cardImage}>
                <img
                  src={opportunityData.host_profile_image}
                  alt=""
                />
              </div>
              <div className={style.cardHeading}>
                <p>{opportunityData.opportunity_title}</p>
              </div>
              <div className={style.foundation}>
                <p>{opportunityData.host_name}</p>
              </div>
              <div className={style.location}>
                <IoLocationOutline /> {opportunityData.location}
              </div>
            </div>

            <div className={style.description}>
                <div className={style.descriptionContent}> <p>
                    {opportunityData.opportunity_discription}
            </p></div>

            </div>
            <div className={style.jobDetails}>
               <p
                        dangerouslySetInnerHTML={{
                          __html: opportunityData.opportunity_details,
                        }}
                      ></p>

            </div>
            <div className={style.buttonSection}><button className={style.jobButton}>Back</button> <button className={style.applyButton}>Apply</button></div>
        </div>
        {showDeleteModal && (
            <div className={style.modal}>
              <div className={style.modalContent}>
                <h2>Are you sure you want to delete this event?</h2>
                <div className={style.modalButtons}>
                  <button onClick={()=>{
                    setShowDeleteModal(false)
                  }}>Cancel</button>
                  <button onClick={opportunityDelete}>Delete</button>
                </div>
              </div>
            </div>
          )}
    </div>
  )
}

export default OpportunityDetails