import React, { useState, useEffect } from "react";
import style from "./MapOrgaNization.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlinePersonSearch } from "react-icons/md";
import {
  Box,
  TextField,
  Alert,
  Snackbar,
  FormControl,
  Autocomplete,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
} from "@mui/material";
import { baseUrl } from "../Url";

const MapOrganization = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  const [userDetail, setUserDetail] = useState(null);
  const [organizationMapping, setOrganizationMapping] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [partners, setPartners] = useState([]);
  const [mapSuccess,setMapSuccess]=useState(false)

  

  const handleCheckboxChange = (org_id) => {
    const newSelectedClubs = selectedOrg.includes(org_id)
      ? selectedOrg.filter((id) => id !== org_id)
      : [...selectedOrg, org_id];

    setSelectedOrg(newSelectedClubs);
  };

  const handleCustomer = (userID) => {
    const selectedCustomer = partners.find(
        (customer) => customer._id === userID
    );
    if (selectedCustomer) {
        setUserDetail(selectedCustomer);
    } else {
        setUserDetail(null); 
    }
};

  async function partnersGet() {
    await fetch(`${baseUrl}/partners/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPartners(data.data);
      });
  }

  async function organizationGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({ user_id: userId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrganizationData(data.data);
      });
  }

  async function mapOrganization() {
    await fetch(`${baseUrl}/partners/org/map`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userDetail._id,
        org_id:selectedOrg
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.status===true){
          setOrganizationMapping(false)
          setMapSuccess(true)
        }
      });
  }

  useEffect(() => {
    console.log(userDetail,"user");
    partnersGet();
    organizationGet();
  }, [key]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          mapSuccess
        }
        sx={{ zIndex: 999999 }}
        autoHideDuration={3000}
        onClose={() => {
         setMapSuccess(false)
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {"Organization mapped successfully"}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.title}>
          <p>Add User to Organization</p>
        </div>
      </div>
      <div className={style.search}>
        <div className={style.searchSection}>
          <div className={style.searchText}>
            <h5>Select a Partner</h5>
          </div>
          <div className={style.searchHeader}>
            <Box sx={{ minWidth: 400 }}>
            <Autocomplete
    id="customer-select"
    options={partners}
    getOptionLabel={(option) => option.Username}
    value={userDetail}
    onChange={(event, newValue) => {
        if (newValue) {
            handleCustomer(newValue._id);
        } else {
            handleCustomer(""); 
        }
    }}
    isOptionEqualToValue={(option, value) =>
        option._id === value._id
    }
    renderInput={(params) => (
        <TextField
            {...params}
            label="Select a User"
            variant="outlined"
            size="small"
            sx={{
                "& fieldset": {
                    borderColor: "#59293a",
                    borderWidth: 2,
                },
                "&:hover fieldset": {
                    borderColor: "#794a57",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#a96b84",
                },
            }}
        />
    )}
/>
            </Box>
          </div>
        </div>
      </div>
      <div className={style.SearchResult}>
        <div className={style.Grid}>
          {userDetail&&(
          <div className={style.card}>
            <div className={style.cardTop}>
              <div className={style.userName}>
                <p>UserName</p>
                <span>:</span>
                <p>{userDetail.Username}</p>
              </div>
              <div className={style.role}>
                <p>Role</p>
                <span>:</span>
                <p>{userDetail.role}</p>
              </div>
              
            </div>

            <div className={style.cardBottom}></div>
            <div className={style.addButton}>
              <button
                onClick={() => {
                  setSelectedOrg((prevSelectedOrg) => {
                    const newOrgIds = userDetail.org_details.map(org => org.org_id);
                    const uniqueNewOrgIds = newOrgIds.filter(orgId => !prevSelectedOrg.includes(orgId));
                    return [...prevSelectedOrg, ...uniqueNewOrgIds];
                  });
                  setOrganizationMapping(true);
                }}
              >
                Add
              </button>
            </div>
          </div>
           )}
        </div>
      </div>
      {organizationMapping ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.courseMapping}>
            <div className={style.courseMapClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrganizationMapping(false);
                  setSelectedOrg([]);
                }}
              />
            </div>
            <div className={style.courseMapSection}>
              <FormControl sx={{ width: "100%", margin: "0 0.25rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Club/Batch
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Organization"
                  multiple
                  value={selectedOrg}
                  onChange={(event) => setSelectedOrg(event.target.value)}
                  MenuProps={menuProps}
                >
                  {organizationData.length > 0 &&
                    organizationData.map((org, index) => {
                      const isSelected = selectedOrg.includes(org.org_id);
                      return (
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={org.org_id}
                          value={org.org_id}
                        >
                          {" "}
                          {org.org_name}
                          <Checkbox
                            name={org.org_id}
                            id={org.org_id}
                            checked={isSelected}
                            onChange={() => handleCheckboxChange(org.org_id)}
                          />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
            <div className={style.courseMapButton}>
              <button disabled={selectedOrg.length<0} onClick={() => {
                mapOrganization();
              }} className={style.create}>
                Map User
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default MapOrganization;
