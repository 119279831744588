import React, { useEffect, useState,useRef } from "react";
import style from "./LessonAdminCreate.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { MdAssessment, MdDelete, MdEditDocument } from "react-icons/md";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoCreateSharp } from "react-icons/io5";
import { Alert, Button, IconButton, Snackbar, Tooltip } from "@mui/material";
import { SyncLoader } from "react-spinners";
import Loader from "../Loader/Loader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ReactQuill,{Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from 'quill-image-resize';
import ImageUploader from 'react-quill-image-uploader';

// Register the image resize module with Quill
Quill.register('modules/imageResize', ImageResize);

const LessonAdminCreate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state;
  console.log(state, "topicDetail");
  const orgId = state.organization;
  const mainTopicId = state.mainTopic;
  const mainCategoryPath =
    state.mainCategory.length > 10
      ? state.mainCategory.substring(0, 10) + "..."
      : state.mainCategory;
  const subcategoryPath = state.subcategories;

  const inputRef=useRef()

  const [editorData, setEditorData] = useState("");
  const [singleTopicName, setSingleTopicName] = useState();
  const [singleTopicId, setSingleTopicId] = useState();
  const [topics, setTopics] = useState([]);
  const [lessonCreate, setLesseonCreate] = useState(false);
  const [creatList, setCreateList] = useState(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageView, setImageView] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [current, setCurrent] = useState(1);
  const [topicDetail, setTopicDetail] = useState([]);
  const [noofpages, setNoOfPages] = useState(1);
  const [lessonEdit, setLesseonEdit] = useState(false);
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [videourl, setVideoUrl] = useState("");
  const [lessonEditData, setLessonEditData] = useState("");
  const [createAiContent, setCreateAiContent] = useState(false);
  const [aiText, setAiText] = useState("");
  const [videoUpload, setVideoUpload] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [learningTitle, setLearningTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [loading, setLoading] = useState(true);
  const [MainLoading, setMainLoading] = useState(false);
  const [lessonDelete, setLessonDelete] = useState(false);
  const [successConfirmation, setSuccessConfirmation] = useState(false);
  const [errorConfirmation, setErrorConfirmation] = useState(false);
  const [lessonCreateStatus, setLessonCreateStatus] = useState(false);
  const [lessonEditStatus, setLessonEditStatus] = useState(false);
  const [lessonDeleteStatus, setLessonDeleteStatus] = useState(false);
  const [imageUploadStatus, setImageUploadStatus] = useState(false);
  const [pdfUpload, setPdfUpload] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPDFURL] = useState("");
  const[mediaCollection,setMediaCollection]=useState([])
  const[mediaPopup,setMediaPopup]=useState(false)
  const[selectedUploadType,setSelectedUploadType]=useState("input")
  const [selectedTab, setSelectedTab] = useState(0);
  const[uploadImageUrl,setUploadImageUrl]=useState("")

  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, ],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'], // Include the 'image' button in the toolbar
        ['clean'],
        [{ 'color': [] }, { 'background': [] }],
      ],
      handlers: {
        image: ImageUploader.handler, // Attach the image handler to the 'image' button
      },
    },
    imageResize: {},
  };

  // Configuration for the text editor
  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image', 
    'color', // Include 'color' in the formats
    'background',
  ];



  const decrement = () => {
    const updatedCurrent = current - 1;
    setCurrent(updatedCurrent);
    Pagination(updatedCurrent);
    setLoading(true);
  };
  const increment = () => {
    const updatedCurrent = current + 1;
    setCurrent(updatedCurrent);
    Pagination(updatedCurrent);
    setLoading(true);
  };

  const handleEditorChange = (html) => {
    setEditorData(html);
  };
  const handleLessonEditorChange = (html) => {
    setEdit(true);
    setLessonEditData(html);
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };
  const handleVideoFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handlePdfFileChange = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleImageUpload = () => {
    inputRef.current.click();
  };


  async function topicSort(id, name) {
    await fetch(`${baseUrl}/topics/pop`, {
      method: "POST",
      body: JSON.stringify({
        main_topic_id: mainTopicId,
        topic_id: id,
        topic_name: name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const topicOption = data.data[0].new_topics.map((topic) => ({
          id: topic.id,
          value: topic.value,
        }));
        console.log(topicOption);
        setTopics(topicOption);
        setSingleTopicId(data.data[0].req_topicid);
        setSingleTopicName(data.data[0].req_topicname);
        setMainLoading(false);
      });
  }

  //lesson list create
  async function adminGenaration() {
    await fetch(`${baseUrl}/admin/lesson/generation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: singleTopicId,
        topic_name: singleTopicName,
        main_topic_id: mainTopicId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAdminId(data.data[0].admin_id);
        setNoOfPages(data.data[0].pages_count);
      });
  }

  async function Pagination(page) {
    setTopicDetail([]);
    await fetch(`${baseUrl}/lesson/pagination/admin_mapped_lesson`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        admin_id: adminId,
        page_num: page,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        setNoOfPages(data.page_length);
        console.log(data.page_length);
        if (data.data && data.data.length > 0) {
          console.log(data);
          setType(data.data[0].type);
          setUniqueId(data.data[0].unique_id);
          if (data.data[0].type === "image") {
            setImageUrl(data.data[0].text);
          } else if (data.data[0].type === "video_url") {
            setVideoUrl(data.data[0].text);
          } else if(data.data[0].type === "pdf"){
            setPDFURL(data.data[0].text)
          }
          else {
            const Text = data.data[0].text.split("\n");
            setTopicDetail(Text);
            setLessonEditData(data.data[0].text);
          }
        }
      });
  }

  //Lesson Create

  const createValidation = () => {
    if (editorData === "") {
      setErrorConfirmation(true)
      setImageUploadStatus(false)

    } else {
      adminLessonCreate()
      setLesseonCreate(false);
      setLoading(true)

    }

  }

  async function adminLessonCreate() {
    await fetch(`${baseUrl}/admin/lesson/upload/text`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        admin_id: adminId,
        content: editorData,
        type: "text",
        title: learningTitle,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          Pagination(data.data.page_num);
          setCurrent(data.data.page_num);
          setLearningTitle("");
          setEditorData("");
          setLessonCreateStatus(true)
          setSuccessConfirmation(true)

        }
      });
  }


  //image upload

  const imageValidation = () => {
    if (image.data === "") {
      setErrorConfirmation(true)
      setImageUploadStatus(true)
    } else {
      imageUpload()
      setLoading(true)

    }
  }
  async function imageUpload() {
    let formData = new FormData();
    formData.append("image", image.data);
    formData.append("admin_id", adminId);
    formData.append("title", learningTitle);
    formData.append("type", "image");
    formData.append("Organization_ID", orgId);
    formData.append("image_url",uploadImageUrl );
    await fetch(`${baseUrl}/gallery/admin/new`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setImageView(false);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setCurrent(data.data[0].page_num);
          setLearningTitle("")

        }
      });
  }

  async function adminAIContentUpload() {
    setLoading(true)

    await fetch(`${baseUrl}/admin/lesson/upload/content_generated_ai`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        admin_id: adminId,
        content: aiText,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCreateAiContent(false);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setCurrent(data.data[0].page_num);
          setAiText("");

        }
      });
  }



  //pdf upload

  async function uploadPDF() {
    let formData = new FormData();
    formData.append("pdf", pdfFile);
    formData.append("admin_id", adminId);
    formData.append("title", learningTitle);
    formData.append("type", "pdf");
    formData.append("Organization_ID", orgId);
    await fetch(`${baseUrl}/pdf/upload/admin`, {
      method: "POST",
      body: formData,
      
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setPdfUpload(false)
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setLearningTitle("");
        }
      });
  }

  //videoupload

  async function uploadVideo() {
    let formData = new FormData();
    formData.append("video", videoFile);
    formData.append("admin_id", adminId);
    formData.append("title", learningTitle);
    formData.append("type", "video");
    formData.append("Organization_ID", orgId);
    await fetch(`${baseUrl}/video/upload/admin`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
           setVideoUpload(false)
          setCurrent(data.data[0].page_num);
          Pagination(data.data[0].page_num);
          setNoOfPages(data.data[0].page_num);
          setLearningTitle("");
        }
      });
  }

  //Lesson Edit

  const editValidation = () => {
    if (lessonEditData === "") {
      setErrorConfirmation(true)
      setImageUploadStatus(false)
    } else {
      adminLessonEdit()
      setLesseonEdit(false);
      setLoading(true)


    }
  }
  async function adminLessonEdit() {
    await fetch(`${baseUrl}/lesson/edit/admin_mapped_lesson`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        admin_id: adminId,
        topic_id: singleTopicId,
        main_topic_id: mainTopicId,
        lesson: lessonEditData,
        page_num: current,
        unique_id: uniqueId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          Pagination(data.data[0].page_number);
          setEdit(false);
          setSuccessConfirmation(true)
          setLessonEditStatus(true)

        }
      });
  }

  //Lesson Delete
  async function chapterDelete() {
    setLoading(true)
    await fetch(`${baseUrl}/lesson/delete/admin_mapped_lesson`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        admin_id: adminId,
        topic_id: singleTopicId,
        main_topic_id: mainTopicId,
        lesson: lessonEditData,
        page_num: current,
        unique_id: uniqueId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        Pagination(1);
        setCurrent(1);
        setSuccessConfirmation(true)
        setLessonDeleteStatus(true)
      });
  }

  async function filesGet() {
    await fetch(`${baseUrl}/gallery/file/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMediaCollection(data.data)
      });
  }



  useEffect(() => {
    topicSort(state.topicId, state.topicName);
    filesGet()
  }, [orgId]);

  useEffect(() => {
    if (singleTopicId && singleTopicName) {
      adminGenaration();
    }
  }, [singleTopicId, singleTopicName]);
  useEffect(() => {
    if (adminId) {
      Pagination(1);

    }
  }, [adminId]);

  useEffect(() => {

    console.log(noofpages);

  }, [noofpages])


  return (
    <div className={style.Container}>

      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={successConfirmation}
        autoHideDuration={3000}
        sx={{ width: "20rem" }}
        onClose={() => {
          setSuccessConfirmation(false)
        }}
      >
        <Alert severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}>
          {lessonCreateStatus ? " Lesson created successfully" : lessonEditStatus ? "Lesson edited successfully" : lessonDeleteStatus ? "Lesson deleted successfully" : ""}

        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorConfirmation}
        autoHideDuration={3000}
        sx={{ width: "20rem" }}
        onClose={() => {
          setErrorConfirmation(false)
        }}
      >
        <Alert severity="error"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}>
          {imageUploadStatus ? "Please select an image" : "  Please enter a lesson"}

        </Alert>
      </Snackbar>
      {MainLoading ? (
        <Loader />
      ) : (
        <div className={style.LessonDiv}>
          <div className={style.LessonHead}>
            <div className={style.navLinks}>
              <div className={style.linkLeft}>
                <p
                  onClick={() => {
                    navigate("/learning");
                  }}
                >
                  {mainCategoryPath}
                </p>
              </div>
              <span>/</span>
              {subcategoryPath.map((category, index) => {
                const truncatedName =
                  category.Name.length > 10
                    ? category.Name.substring(0, 10) + "..."
                    : category.Name;
                return (
                  <div className={style.linkRight} key={category.category_id}>
                    {index > 0 ? <span>/</span> : ""}
                    <p
                      onClick={() => {
                        navigate("/learning");
                      }}
                    >
                      {truncatedName}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className={style.topicList}>
              <Autocomplete
                id="disable-clearable"
                disableClearable
                options={topics}
                getOptionLabel={(option) => option.value}
                value={{ id: singleTopicId, value: singleTopicName }}
                sx={{
                  width: "65%",
                  // borderBottom: "1px solid #000",
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   border: "none",
                  // },
                }}
                renderInput={(params) => <TextField {...params} label="" />}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: "1.25rem" }}>
                    {option.value}
                  </li>
                )}
                onChange={(event, newValue) => {
                  console.log(newValue.id, newValue.value);
                  topicSort(newValue.id, newValue.value);
                  setMainLoading(true);
                  setCurrent(1)
                }}
              />
            </div>
            <div className={style.lessonButtons}>
              <div className={style.lessonButtonLeft}>
                {noofpages >= 1 ? (
                  <div className={style.Pagination}>
                    <IconButton
                      aria-label="delete"
                      disabled={current === 1}
                      onClick={() => {
                        decrement();
                      }}
                      style={{
                        color: "blue",
                        fontSize: "1.75rem",
                        margin: "0 0.25rem",
                      }}
                    >
                      <GrFormPrevious />
                    </IconButton>

                    <p>{current}</p>
                    <span>/</span>
                    <p>{noofpages}</p>

                    <IconButton
                      aria-label="delete"
                      disabled={
                        current === noofpages ||
                        noofpages === 1 ||
                        noofpages === 0
                      }
                      onClick={() => {
                        increment();
                      }}
                      style={{
                        color: "blue",
                        fontSize: "1.75rem",
                        margin: "0 0.25rem",
                      }}
                    >
                      <GrFormNext />
                    </IconButton>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={style.lessonButtonRight}>
                <div className={style.chapterCreate}>
                  <Tooltip title="Chapter Create">
                    <IconButton>
                      {" "}
                      <IoCreateSharp
                        onClick={() => {
                          setCreateList(true);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                {noofpages >= 1 ? (
                  <div className={style.chapterEdit}>
                    <Tooltip title="Chapter Edit">
                      <IconButton>
                        <MdEditDocument
                          onClick={() => {
                            setLesseonEdit(true);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
                <div className={style.chapterDelete}>
                  <Tooltip title="Chapter Delete">
                    <IconButton>
                      <MdDelete
                        onClick={() => {
                          setLessonDelete(true);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className={style.assessmentView}>
                  <Tooltip title="Assessment View">
                    <IconButton>
                      <MdAssessment
                        onClick={() => {
                          navigate("/asessments", {
                            state: {
                              organization: orgId,
                              mainTopic: mainTopicId,
                              topicName: singleTopicName,
                              topicId: singleTopicId,
                              subcategories: state.subcategories,
                              mainCategory: state.mainCategory,
                            },
                          });
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div className={style.topicDetal}>
            {loading ? (
              <div className={style.loader}>
                <SyncLoader
                  color="#36D7B7"
                  size={15}
                  margin={5}
                  loading={true}
                />
              </div>
            ) : (
              <>
                {noofpages >= 1 ? (
                  <>
                    {type === "image" ? (
                      <div className={style.imageView}>
                        <img src={imageUrl} alt=" currently unavailable" />
                      </div>
                    ) : type === "video_url" ? (
                      <div>
                        <video controls width="1000" height="560">
                          <source src={videourl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : type === "pdf" ? (
                      <div style={{width:"100%"}}>
                      <iframe
                        title="PDF Viewer"
                        src={pdfUrl}
                        width="100%"
                        height="560px"
                      />
                    </div>
                    ) : (
                      <>
                        {topicDetail.map((text, index) => {
                          return (
                            <p
                              key={index}
                              className={text ? style.TextContent : style.Blank}
                            >
                              {text ? (
                                <span
                                  dangerouslySetInnerHTML={{ __html: text }}
                                />
                              ) : (
                                "Blank"
                              )}
                            </p>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <div className={style.noSelection}>
                    <Alert severity="info">Please create a chapter</Alert>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {creatList ? (
        <>
          <div
            onClick={() => {
              setCreateList(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createList}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Upload my Lesson</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setCreateList(false);
                  }}
                />
              </div>
            </div>

            <div className={style.listSection}>
              <div
                onClick={() => {
                  setCreateAiContent(true);
                  setCreateList(false);
                }}
                className={style.sectionValue}
              >
                <input type="radio" name="" id="" />
                <p>Content Generated By AI</p>
              </div>
              <div
                onClick={() => {
                  setLesseonCreate(true);
                  setCreateList(false);
                }}
                className={style.sectionValue}
              >
                <input type="radio" name="" id="" />
                <p>Copy and paste lesson</p>
              </div>
              <div
                onClick={() => {
                  setImageView(true);
                  setCreateList(false);
                }}
                className={style.sectionValue}
              >
                <input type="radio" name="" id="" />

                <p>Upload lesson as attachment</p>
              </div>
              <div
                onClick={() => {
                  setCreateList(false);
                  setVideoUpload(true);
                }}
                className={style.sectionValue}
              >
                <input type="radio" name="" id="" />

                <p>Upload lesson as video</p>
              </div>
              <div
                onClick={() => {
                  setCreateList(false);
                  setPdfUpload(true)
                 
                }}
                className={style.sectionValue}
              >
                <input type="radio" name="" id="" />

                <p>Upload lesson as pdf</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {lessonCreate ? (
        <>
          <div
            onClick={() => {
              setLesseonCreate(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createListLesson}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Copy and paste lesson</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setLesseonCreate(false);
                  }}
                />
              </div>
            </div>
            {/* <div className={style.TitleInput}>
              <input
                value={learningTitle}
                onChange={(e) => {
                  setLearningTitle(e.target.value);
                }}
                placeholder="Title here"
              />
            </div> */}
            <div className={style.editSection}>
            <ReactQuill
                    theme="snow"
                    value={editorData}
                    modules={modules} // Pass the modules configuration
                    formats={formats} 
                    onChange={handleEditorChange}
                    style={{ width: "100%", height: "90%" }}
                  />
             
            </div>
            <div className={style.addButton}>
              <button
                onClick={() => {
                  createValidation();

                }}
              >
                Add Lesson
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {lessonEdit ? (
        <>
          <div
            onClick={() => {
              setLesseonEdit(false);
              setEdit(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createListLesson}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Edit lesson</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setLesseonEdit(false);
                    setEdit(false);
                  }}
                />
              </div>
            </div>
            {/* <div className={style.TitleInput}>
              <input
                value={learningTitle}
                onChange={(e) => {
                  setLearningTitle(e.target.value);
                }}
                placeholder="Title here"
              />
            </div> */}
            <div className={style.editSection}>
            <ReactQuill
                    theme="snow"
                    value={lessonEditData}
                    modules={modules} // Pass the modules configuration
                    formats={formats} 
                    onChange={handleLessonEditorChange}
                    style={{ width: "100%", height: "90%" }}
                  />
              
            </div>
            <div className={style.addButton}>
              <button
                onClick={() => {
                  editValidation();
                }}
              >
                Update Lesson
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {createAiContent ? (
        <>
          <div
            onClick={() => {
              setCreateAiContent(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createList}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Content Generated By AI</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setCreateAiContent(false);
                  }}
                />
              </div>
            </div>
            {/* <div className={style.TitleInput}>
                      <input value={learningTitle} onChange={(e)=>{
                        setLearningTitle(e.target.value)
                      }} placeholder="Title here"/>
                    </div> */}
            <div className={style.aigenerate}>
              <textarea
                name=""
                cols="30"
                rows="10"
                onChange={(e) => {
                  setAiText(e.target.value);
                }}
              ></textarea>

              <div className={style.aigenerateButton}>
                <button
                  onClick={() => {
                    adminAIContentUpload();
                  }}
                  disabled={aiText === ""}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {imageView ? (
        <>
          <div
            onClick={() => {
              setImageView(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createList}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Upload lesson as attachment</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setImageView(false);
                    setMediaPopup(false)
                  }}
                />
              </div>
            </div>
            <div className={style.TitleInput}>
              <input
                value={learningTitle}
                onChange={(e) => {
                  setLearningTitle(e.target.value);
                }}
                placeholder="Title here"
              />
            </div>
            <div className={style.uploadSection}>
              <div className={style.inputSection}>
                {uploadImageUrl!==""?<p>{uploadImageUrl}</p>:image.data!==""?<p>{image.preview}</p>:<button onClick={()=>{
                  setMediaPopup(true)
                }}>Choose an image</button>}
                <input
                  ref={inputRef}
                  type="file"
                  id="avatar"
                  name="avatar"
                  style={{display:"none"}}
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                />
                {uploadImageUrl!==""||image.data!==""?
                <IconButton
                        aria-label="delete"
                        color="error"
                        sx={{padding:"0"}}
                       onClick={()=>{
                        setUploadImageUrl("")
                        setImage({ preview: "", data: "" })
                       }}
                      >
                        <MdDelete />
                      </IconButton>
                      :""}
              </div>
              <div className={style.UploadButton}>
                <button
                  onClick={() => {
                    imageValidation();
                  }}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {videoUpload ? (
        <>
          <div
            onClick={() => {
              setVideoUpload(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createList}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Upload lesson as video</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setVideoUpload(false);
                  }}
                />
              </div>
            </div>
            <div className={style.TitleInput}>
              <input
                value={learningTitle}
                onChange={(e) => {
                  setLearningTitle(e.target.value);
                }}
                placeholder="Title here"
              />
            </div>
            <div className={style.uploadSection}>
              <div className={style.inputSection}>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="video/*"
                  onChange={handleVideoFileChange}
                />
              </div>
              <div className={style.UploadButton}>
                <button onClick={() => { 
                  if(videoFile!==null){
                    uploadVideo()
                  }
                }}>Upload</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {pdfUpload ? (
        <>
          <div
            onClick={() => {
              setPdfUpload(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.createList}>
            <div className={style.listHeader}>
              <div className={style.listText}>
                <p>Upload lesson as PDF</p>
              </div>
              <div className={style.listClose}>
                <AiOutlineClose
                  onClick={() => {
                    setPdfUpload(false);
                  }}
                />
              </div>
            </div>
            <div className={style.TitleInput}>
              <input
                value={learningTitle}
                onChange={(e) => {
                  setLearningTitle(e.target.value);
                }}
                placeholder="Title here"
              />
            </div>
            <div className={style.uploadSection}>
              <div className={style.inputSection}>
              <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept="application/pdf"
                        onChange={handlePdfFileChange}
                      />
              </div>
              <div className={style.UploadButton}>
                <button onClick={() => {
                  if(pdfFile!==null){
                    uploadPDF()
                  }
                 }}>Upload</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {lessonDelete ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.DeletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setLessonDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete?</p>
              </div>
              <div className={style.deleteButtons}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    setLessonDelete(false);
                    chapterDelete();
                  }}
                >
                  Delete
                </button>

                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setLessonDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {mediaPopup?
      <>
      <div className={style.Overlay}>
      </div>
      <div className={style.MediaPopup}>
            <div className={style.MediaPopupHeader}>
              <div className={style.MediaPopupHeaderText}>
                <h5>Attachment Details</h5>
              </div>

              <div className={style.MediaPopupHeaderClose}>
                <AiOutlineClose
                  onClick={() => {
                    setMediaPopup(false)
                  }}
                />
              </div>
            </div>
            <div className={style.MediaPopupSection}>
            <div className={style.tabSection}>
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
              value={selectedTab}
              onChange={handleTabChange}
              centered
            >
              <Tab
                label="Images"
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
              />
              <Tab
                label="Input"
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d",
                  backgroundColor: "",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
               
              />
              
            </Tabs>
          </div>
          <Typography>
            {selectedTab===0&&(
              <div className={style.ImageGRid}>
                {mediaCollection&&mediaCollection.length>0?mediaCollection.map((item,index)=>{
              return(
              <div key={index} onClick={()=>{
                setUploadImageUrl(item.file_url)
                setMediaPopup(false)
              }}  className={style.Card}>
              <img src={item.file_url}/>
            </div>
              )
            }):''}
              </div>
            )}
            {selectedTab===1&&(
              <div className={style.MediaPopupInputTab}>
                <button onClick={()=>{handleImageUpload()
                setMediaPopup(false)}} >Select File</button>
              </div>
            )}
          </Typography>
              
            </div>
          </div>
      </>

      :''}
    </div>
  );
};

export default LessonAdminCreate;
