import React, { useEffect, useState, useRef } from "react";
import style from "./OpportunityCreation.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuImagePlus } from "react-icons/lu";
import { baseUrl } from "../Url";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomQuill = styled(ReactQuill)`
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border: none;
  }
`;

const OpportunityCreation = () => {
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.profileData);

  const [isFocused, setIsFocused] = useState(false);
  const [image, setImage] = useState(null);
  const [hostName, setHostName] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [eventMode, setEventMode] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen2, setSnackbarOpen2] = useState(false);
  const [snackbarMessage2, setSnackbarMessage2] = useState("");
  const [snackbarSeverity2, setSnackbarSeverity2] = useState("success");
  const [location, setLocation] = useState("");

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImage(null);
  };

  const CreateOpportunity = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!eventTitle.trim()) {
      setSnackbarMessage("Opportunity title must not be empty.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      isValid = false;
    }

    if (!eventDetails.trim()) {
      setSnackbarMessage2("Opportunity details must not be empty.");
      setSnackbarSeverity2("error");
      setSnackbarOpen2(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("user_name", userName);
      formData.append("user_id", userId);
      formData.append("opportunity_title", eventTitle);
      formData.append("host_name", hostName);
      formData.append("opportunity_discription", description);
      formData.append("opportunity_details", eventDetails);
      formData.append("location", location);
      formData.append("end_date", endDate);
      if (fileInputRef.current.files[0]) {
        formData.append("host_profile_image", fileInputRef.current.files[0]);
      }

      const response = await fetch(`${baseUrl}/duno/opportunity/add`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (result.status) {
        setSnackbarMessage("Opportunity added successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/opportunity");
        }, 2000);
      } else {
        setSnackbarMessage("Failed to add Opportunity: " + result.description);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding event:", error);
      setSnackbarMessage("An error occurred while adding the Opportunity.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (profile) {
      setUserName(profile?.Username);
      setUserId(profile?._id);
    }
  }, []);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <h3>Add your Opportunity</h3>
        </div>
      </div>
      <div className={style.form}>
        <div className={style.createBox}>
          <form onSubmit={CreateOpportunity}>
            <div className={style.hostName}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Host Name"
                  variant="outlined"
                  value={hostName}
                  onChange={(e) => setHostName(e.target.value)}
                  required
                />
              </Box>
            </div>

            <div className={style.eventTitle}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Opportunity Title"
                  variant="outlined"
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                  required
                />
              </Box>
            </div>

            <div className={style.discription}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Box>
            </div>

            <div className={style.eventDetialsBox}>
              <p>Details</p>
              <div
                className={`${style.eventDetails} ${
                  isFocused ? style.focused : ""
                }`}
              >
                <div className={style.quillBox}>
                  <CustomQuill
                    className={style.quill}
                    theme="snow"
                    value={eventDetails}
                    onChange={setEventDetails}
                    onFocus={() => setIsFocused(true)}
                  />
                </div>
              </div>
            </div>

            <div className={style.modePosterTime}>
              <div className={style.dateTime}>
                <div className={style.modeContainer}>
                  <div className={style.modeMenu}>
                    <TextField
                      id="outlined-basic"
                      label="Location"
                      variant="outlined"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      fullWidth
                      autoComplete="off"
                    />
                  </div>
                  {/* <div className={style.startDate}>
                  <label>Start Date & Time </label>
                  <input
                    type="datetime-local"
                    name="startDate"
                    id="startDate"
                    required
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div> */}
                  <div className={style.endDate}>
                    <label>End Date & Time</label>
                    <input
                      type="datetime-local"
                      name="endDate"
                      id="endDate"
                      required
                      placeholder="End Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>

                {/* <div className={style.venueBox}>
                <div className={style.venueContianer}>
                  <div className={style.venue}>
                    <p>Venue/Links</p>
                    <CustomQuill
                      className={style.quill}
                      theme="snow"
                      value={eventVenue}
                      onChange={setEventVenue}
                      placeholder="Enter venue details or links"
                    />
                  </div>
                </div>
              </div> */}
              </div>

              <div className={style.eventPoster}>
                <div className={style.posterContainer}>
                  <div className={style.uploadBtn}>
                    <input
                      accept="image/*"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <button type="button" onClick={handleButtonClick}>
                      Add Image <LuImagePlus className={style.imgIcon} />
                    </button>
                  </div>
                  {image && (
                    <div className={style.posterImage}>
                      <img src={image} alt="Event Poster" />
                      <FaRegTrashAlt
                        className={style.remove}
                        title="remove"
                        onClick={handleImageRemove}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={style.submitButton}>
              <button type="submit"> Submit Opportunity </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen2}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen2(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen2(false)}
          severity={snackbarSeverity2}
          sx={{ width: "100%" }}
        >
          {snackbarMessage2}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OpportunityCreation;
