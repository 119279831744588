import React, { useEffect, useState } from "react";
import style from "./Lab.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { baseUrl } from "../Url";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";

const Lab = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const userId = localStorage.getItem("adminUserId");

  const location = useLocation();
  const state = location.state;
  const topic_id = state?.topicId;
  const topic_name = state?.topicName;

  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [labName, setLabName] = useState("");
  const [labUrl, setLabUrl] = useState("");
  const [editLabName, setEditLabName] = useState("");
  const [editLabUrl, setEditLabUrl] = useState("");
  const [labID, setLabID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [labData, setLabData] = useState([]);
  const [editSuccess, setEditSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const validateFields = () => {
    if (!labName) {
      setErrorMessage("Lab Name is required");
      return false;
    }
    if (!labUrl) {
      setErrorMessage("Lab Url is required");
      return false;
    }

    return true;
  };

  const validateEditFields = () => {
    if (!editLabName) {
      setErrorMessage("Lab Name is required");
      return false;
    }
    if (!editLabUrl) {
      setErrorMessage("Lab Url is required");
      return false;
    }

    return true;
  };

  async function labCreation() {
    if (!validateFields()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/labs/add`, {
        method: "POST",
        body: JSON.stringify({
          user_id: userId,
          topic_id: topic_id,
          topic_name: topic_name,
          lab_name: labName,
          lab_link: labUrl,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setCreatePopup(false);
            setCreateSuccess(true);
            setLabName("");
            setLabUrl("");
            labGet();
          }
        });
    }
  }

  async function labGet() {
    await fetch(`${baseUrl}/topic/labs/list`, {
      method: "POST",
      body: JSON.stringify({ topic_id: topic_id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLabData(data.data);
      });
  }

  async function labUpdation() {
    if (!validateEditFields()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/labs/edit`, {
        method: "POST",
        body: JSON.stringify({
          lab_id: labID,
          user_id: userId,
          topic_id: topic_id,
          topic_name: topic_name,
          lab_name: editLabName,
          lab_link: editLabUrl,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setEditPopup(false);
            setEditSuccess(true);
            setEditLabName("");
            setEditLabUrl("");
            labGet();
          }
        });
    }
  }

  async function labDeletion() {
    await fetch(`${baseUrl}/labs/delete`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        lab_id: labID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          setDeleteSuccess(true);
          labGet();
        }
      });
  }

  useEffect(() => {
    labGet();
  }, [topic_id]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess || editSuccess || deleteSuccess}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
          setDeleteSuccess(false);
          setEditSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "Lab created successfully"
            : deleteSuccess
            ? "Lab deleted successfully"
            : editSuccess
            ? "Lab updated successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Labs</p>
        </div>
        <div className={style.headButton}>
          <button
            onClick={() => {
              setCreatePopup(true);
            }}
          >
            Create
          </button>
        </div>
      </div>
      <div className={style.body}>
        <Table stickyHeader sx={{ width: "70%" }} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key="batch"
                align="center"
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  color: "#535370",
                  background: "#f8faff",
                  zIndex: "0",
                }}
              >
                Lab Name
              </TableCell>
              <TableCell
                key="course"
                align="center"
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  color: "#535370",
                  background: "#f8faff",
                  zIndex: "0",
                }}
              >
                Url
              </TableCell>

              <TableCell
                key="actions"
                align="center"
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  color: "#535370",
                  background: "#f8faff",
                  zIndex: "0",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labData && labData.length > 0
              ? labData.map((lab, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        key="rate"
                        align="left"
                        style={{
                          border: "1px solid #e8e4ef",
                          fontSize: "1rem",
                        }}
                      >
                        {lab.lab_name}
                      </TableCell>
                      <TableCell
                        key="currency"
                        align="left"
                        style={{
                          border: "1px solid #e8e4ef",
                          fontSize: "1rem",
                          padding: "8px",
                        }}
                      >
                        {lab.lab_link}
                      </TableCell>

                      <TableCell
                        key="action"
                        align="left"
                        style={{
                          border: "1px solid #e8e4ef",
                          fontSize: "1rem",
                          padding: "8px",
                        }}
                      >
                        <div className={style.Buttons}>
                          <div className={style.iconContainer}>
                            <BiEdit
                              className={style.editButton}
                              onClick={() => {
                                setEditLabName(lab.lab_name);
                                setEditLabUrl(lab.lab_link);
                                setLabID(lab.lab_id);
                                setEditPopup(true);
                              }}
                            />
                          </div>
                          <div className={style.iconContainer}>
                            <RiDeleteBinLine
                              className={style.deleteButton}
                              onClick={() => {
                                setDeletePopup(true);
                                setLabID(lab.lab_id);
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </div>
      {createPopup ? (
        <>
          <div onClick={() => {}} className={style.Overlay}></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setCreatePopup(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <div className={style.sectionTop}>
                <div className={style.orgName}>
                  <label htmlFor="">Lab Name</label>
                  <input
                    type="text"
                    value={labName}
                    onChange={(e) => {
                      setLabName(e.target.value);
                    }}
                    name=""
                    id=""
                    placeholder=""
                  />
                </div>
                <div className={style.description}>
                  <label htmlFor="">URL</label>
                  <input
                    type="text"
                    value={labUrl}
                    onChange={(e) => {
                      setLabUrl(e.target.value);
                    }}
                    name=""
                    id=""
                    required
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  labCreation();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setCreatePopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {editPopup ? (
        <>
          <div onClick={() => {}} className={style.Overlay}></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setEditPopup(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <div className={style.sectionTop}>
                <div className={style.orgName}>
                  <label htmlFor="">Lab Name</label>
                  <input
                    type="text"
                    value={editLabName}
                    onChange={(e) => {
                      setEditLabName(e.target.value);
                    }}
                    name=""
                    id=""
                    placeholder=""
                  />
                </div>
                <div className={style.description}>
                  <label htmlFor="">URL</label>
                  <input
                    type="text"
                    value={editLabUrl}
                    onChange={(e) => {
                      setEditLabUrl(e.target.value);
                    }}
                    name=""
                    id=""
                    required
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  labUpdation();
                }}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setEditPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deletePopup ? (
        <>
          <div
            onClick={() => {
              setDeletePopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setDeletePopup(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p> Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    labDeletion();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Lab;
